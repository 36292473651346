@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@import url('https://cdn.quilljs.com/1.2.2/quill.snow.css');
@import url('https://cdn.quilljs.com/1.2.2/quill.bubble.css');

// indigo-pink, deeppurple-amber, purple-green and pink-bluegrey.
@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import "app/theme/styles/base";
@import "app/theme/styles/theme";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/rtl";

.loading-spinner {

    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    
}
$font-family: 'Roboto', sans-serif;

$toolbar-height: 56px; 
$horizontal-menu-height: $toolbar-height;

$sidenav-width: 250px;
$sidenav-user-block-height: 156px;
$mini-sidenav-user-block-height: 70px;

$flex-distance: 8px;
$inner-sidenav-content-padding: $flex-distance;

//Login, Register, Errors box
$box-height: 186px;
$box-position: 34px;
$register-box-height: 300px;
@use '@angular/material' as mat;
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

@import 'theme-reset';
.app{
    &.indigo-light{
        @import "skins/indigo-light";
        @include mat.all-legacy-component-themes($indigo-light-theme);
        @include theme-reset($indigo-light-theme);
    }
    &.teal-light{
        @import "skins/teal-light";
        @include mat.all-legacy-component-themes($teal-light-theme);
        @include theme-reset($teal-light-theme);
    }
     &.red-light{
        @import "skins/red-light";
        @include mat.all-legacy-component-themes($red-light-theme);
        @include theme-reset($red-light-theme);
    }
    &.blue-dark{
        @import "skins/blue-dark";
        @include mat.all-legacy-component-themes($blue-dark-theme);
        @include theme-reset($blue-dark-theme);
    }
    &.green-dark{
        @import "skins/green-dark";
        @include mat.all-legacy-component-themes($green-dark-theme);
        @include theme-reset($green-dark-theme);
    }
    &.pink-dark{
        @import "skins/pink-dark";
        @include mat.all-legacy-component-themes($pink-dark-theme);
        @include theme-reset($pink-dark-theme);
    }
}